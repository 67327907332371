import React, { createContext, useEffect, useReducer } from "react";
import { gql } from "@apollo/client";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client/react";
import { CART } from "../lib/apollo/fragments";
import { sendSentryError } from "../lib/sentry/sentry";
import { DiscountT, ValueType } from "../utils/shop";

export const CREATE_CART = gql`
  mutation cartCreate {
    cartCreate {
      cart {
        id
        checkoutUrl
      }
    }
  }
`;

const FETCH_CART = gql`
  ${CART}
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      estimatedCost {
        subtotalAmount {
          amount
          currencyCode
        }
      }
      ...CART
    }
  }
`;

const REMOVE_FROM_CART = gql`
  ${CART}
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(lineIds: $lineIds, cartId: $cartId) {
      cart {
        ...CART
      }
    }
  }
`;

const FETCH_CART_LINES = gql`
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      lines(first: 20) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export enum ShopReducerTypes {
  CreateCart = "CREATE_CART",
  AddToCart = "ADD_TO_CART",
  UpdateCartItem = "UPDATE_CART_ITEM",
  DeleteCartItem = "REMOVE_CART_ITEM",
  AddDiscount = "ADD_DISCOUNT",
}

type CartType = {
  id: string;
  checkoutUrl: string;
};

type ShopStateType = {
  cart: CartType;
  discountMap: {
    [productId: string]: DiscountT & {
      code: string;
    };
  };
};

const initialState = {
  cart:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("cart") || "{}")
      : {},
  discountMap: {
    "7506140725425": {
      // household subscription
      value_type: "percentage" as ValueType,
      value: "100",
      code: "Free 3 Months",
    },
    "7506140758193": {
      // individual subscription
      value_type: "percentage" as ValueType,
      value: "100",
      code: "Free 3 Months",
    },
  },
};

const ShopReducer = (
  state: ShopStateType,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ShopReducerTypes.CreateCart:
      localStorage.setItem("cart", JSON.stringify(payload));
      return { ...state, cart: payload };
    case ShopReducerTypes.AddToCart:
      return { ...state };
    case ShopReducerTypes.UpdateCartItem:
      return { ...state };
    case ShopReducerTypes.DeleteCartItem:
      return { ...state };
    case ShopReducerTypes.AddDiscount:
      return {
        ...state,
        discountMap: {
          ...state.discountMap,
          [payload.productId]: {
            value_type: payload.value_type,
            value: payload.value,
            code: payload.code,
          },
        },
      };
    default:
      return state;
  }
};

export const ShopContext = createContext<{
  state: ShopStateType;
  dispatch: React.Dispatch<any>;
  clearCart: () => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  clearCart: async () => {},
});

export const useShopContext = () => {
  const context = React.useContext(ShopContext);
  if (context === undefined) {
    throw new Error("useShopContext must be used within a ShopContextProvider");
  }
  return context;
}

//@ts-ignore
export const ShopContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ShopReducer, initialState);

  const [createCheckout] = useMutation(CREATE_CART, {
    context: { clientName: "shopify" },
    onCompleted: ({ cartCreate }) => {
      console.log("cart created", cartCreate?.cart);
      dispatch({
        type: ShopReducerTypes.CreateCart,
        payload: cartCreate?.cart,
      });
    },
    onError: (error) => {
      sendSentryError("shopify mutation cartCreate", error);
      console.log("shopify mutation cartCreate");
    },
  });

  const createCart = async () => {
    await createCheckout();
  };

  const clearCart = async () => {
    const cartId = state?.cart?.id;
    if (!cartId) return;

    const cartLineResult = await fetchCartLines({
      variables: {
        cartId: cartId,
      },
    });
    const cartLineIds = cartLineResult.data?.cart?.lines?.edges?.map(
      (edge: any) => edge.node.id
    );

    await cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: cartLineIds,
      },
    });
  };

  const { data } = useQuery(FETCH_CART, {
    skip: !state?.cart?.id,
    variables: {
      cartId: state?.cart?.id,
    },
    context: {
      clientName: "shopify",
    },
  });

  const [cartLinesRemove] = useMutation(REMOVE_FROM_CART, {
    context: { clientName: "shopify" },
    onCompleted: ({ cartLinesRemove }) => {
      console.log("item removed from cart", cartLinesRemove);
      dispatch({
        type: ShopReducerTypes.DeleteCartItem,
        payload: cartLinesRemove,
      });
    },
    onError: (error) => {
      console.log("cartLinesRemove error", error);
    },
  });

  const [fetchCartLines] = useLazyQuery(FETCH_CART_LINES, {
    fetchPolicy: "no-cache",
    context: { clientName: "shopify" },
    onError: (error) => {
      console.error("fetchCartLines error", error);
    },
  });

  useEffect(() => {
    if (!state?.cart?.id || (data && data?.cart === null)) {
      createCart();
    }
  }, [data?.cart]);

  return (
    <ShopContext.Provider value={{ state, dispatch, clearCart }}>
      {children}
    </ShopContext.Provider>
  );
};
