import { AnalyticsBrowser } from "@segment/analytics-next";

export const segmentUserAuthenticatedAnalytics = new AnalyticsBrowser();

segmentUserAuthenticatedAnalytics.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY as string,
});

// For unauthenticated events
export const segmentUserUnauthenticatedAnalytics = new AnalyticsBrowser();

segmentUserUnauthenticatedAnalytics.load({
  writeKey: process.env
    .NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY as string,
});
