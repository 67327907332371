import "../styles/globals.css";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { useEffect, ReactNode, ReactElement } from "react";
import { UIContextProvider } from "../context/UIContext";
import Head from "next/head";
import { useRouter } from "next/router";
import { ApolloProvider, gql } from "@apollo/client";
import { useApollo } from "../lib/apollo/apollo-client";
import { ShopContextProvider } from "../context/ShopContext";
import localFont from "next/font/local";
import { Inter } from "next/font/google";

import {
  everflowPageViewAnalytics,
  pageViewAnalytics,
  qrCodeAnalytics,
  segmentIdentify,
} from "../utils/analytics";
import { initializeLogRocket } from "../lib/logrocket/logrocket";
import * as Sentry from "@sentry/nextjs";

import { logout, parseAuthJWT } from "../lib/auth0/auth";
import LogRocket from "logrocket";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clsx } from "clsx";
import Image from "next/image";
import Close from "public/icons/close-white.svg";
import { getCookie } from "cookies-next";
import { capitalize } from "../utils/misc";
import useCachedQueryParam from "@/hooks/useCachedQueryParam";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const fontModernGothic = localFont({
  src: [
    {
      path: "../public/asset/ModernGothic/ModernGothic-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    // {
    //   path: "../public/asset/ModernGothic/ModernGothic-Italic.woff2",
    //   weight: "400",
    //   style: "italic",
    // },
    {
      path: "../public/asset/ModernGothic/ModernGothic-Light.otf",
      weight: "300",
      style: "normal",
    },
  ],
});

const inter = Inter({ subsets: ["latin"] });

const fontModernGothicTrial = localFont({
  src: [
    // {
    //   path: "../public/asset/ModernGothicTrial/ModernGothic-Regular-trial.otf",
    //   weight: "400",
    //   style: "normal",
    // },
    {
      path: "../public/asset/ModernGothicTrial/ModernGothic-Light-trial.otf",
      weight: "300",
      style: "normal",
    },
  ],
});

const GET_USER = gql`
  query User($auth0UserId: String!) {
    User(where: { auth0UserId: { _eq: $auth0UserId } }) {
      id
      birthday
      gender
      email
      firstName
      lastName
      cuid
    }
  }
`;

const GET_USER_CUID = gql`
  query User($afWebUserId: String!) {
    User(where: { cuid: { _eq: $afWebUserId } }) {
      id
      birthday
      gender
      email
      firstName
      lastName
      auth0UserId
      cuid
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: String!, $cuid: String!) {
    updateUser(id: $id, email: $email) {
      id
      cuid
    }
  }
`;

const UPDATE_USER_CUID = gql`
  mutation update_User($userId: String!, $afWebUserId: String!) {
    update_User(where: { id: { _eq: $userId } }, _set: { cuid: $afWebUserId }) {
      returning {
        id
        birthday
        gender
        email
        firstName
        lastName
        auth0UserId
        cuid
      }
    }
  }
`;

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);
  const apolloClient = useApollo(pageProps);
  const auth0UserId = parseAuthJWT("id");
  useCachedQueryParam("promocode");
  // const [
  //   updateUserCUID,
  //   {
  //     data: dataUpdateUser,
  //     loading: loadingUpdateUser,
  //     error: errorUpdateUser,
  //   },
  // ] = useMutation(UPDATE_USER, {
  //   context: { clientName: "rapptr" },
  //   onCompleted: ({}) => {
  //     console.log("udated");
  //   },
  //   onError: (error) => {
  //     sendSentryError("mutation updateUser", error);
  //     console.log("mutation updateUser");
  //   },
  // });

  // let vh = typeof window !== "undefined" ? window.innerHeight * 0.01 : 100;

  // const [vh, setVH] = useState<number>(0);

  //PWA stuff
  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     window.addEventListener("load", function () {
  //       navigator.serviceWorker.register("/sw.js").then(
  //         function (registration) {
  //           console.log(
  //             "Service Worker registration successful with scope: ",
  //             registration.scope
  //           );
  //         },
  //         function (err) {
  //           console.log("Service Worker registration failed: ", err);
  //         }
  //       );
  //     });
  //   }
  // }, []);

  const { utm_source, utm_medium, utm_campaign } = router.query;

  useEffect(() => {
    if (typeof window !== undefined) {
      initializeLogRocket();

      const afUserId = getCookie("afUserId");

      if (afUserId && typeof afUserId === "string") {
        // always set CUID
        window.AF("pba", "setCustomerUserId", afUserId);
      }
    }
  }, []);

  useEffect(() => {
    // on app load, scroll to the position saved in sessionStorage
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    }

    // save scroll position when navigating away from page
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("beforeunload", saveScrollPosition);

    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);

  useEffect(() => {
    //page analytics
    if (typeof window !== undefined) {
      pageViewAnalytics(router?.asPath, generateWebPageTitle(router.asPath));
    }
  }, [router.asPath]);

  useEffect(() => {
    router.events.on("routeChangeComplete", everflowPageViewAnalytics);

    return () => {
      router.events.off("routeChangeComplete", everflowPageViewAnalytics);
    };
  }, [router.events]);

  useEffect(() => {
    //qr analytics
    if (typeof window !== undefined) {
      if (utm_source && utm_medium && utm_campaign) {
        qrCodeAnalytics(utm_source, utm_medium, utm_campaign);
        if (
          utm_source === "S2 Delivery Assets" &&
          utm_medium === "QR" &&
          utm_campaign === "Strap Insert Card"
        ) {
          window.location.href = "https://alter.onelink.me/CGdK/hspy21dh";
        } else if (utm_campaign === "Screen Setup Instructions") {
          return;
        }
      }
    }
  }, [utm_source && utm_medium && utm_campaign]);

  useEffect(() => {
    const afUserId = getCookie("afUserId");
    //checks if auth token is real and sets up indentify stuff for third parties
    if (
      typeof window !== undefined &&
      auth0UserId &&
      typeof afUserId === "string"
    ) {
      apolloClient
        .query({
          query: GET_USER,

          variables: {
            auth0UserId: auth0UserId,
          },
          context: { clientName: "rapptr" },
        })
        .then((response: any) => {
          if (response?.data?.User?.length > 0) {
            const user = response?.data?.User[0];
            // https://segment.com/docs/connections/spec/identify/#traits
            segmentIdentify({
              id: user?.id,
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
              birthday: user?.birthday,
              gender: user?.gender,
              "x-hasura-role": parseAuthJWT("role"),
            });
            LogRocket.identify(user?.id, {
              email: user?.email,
              name: user?.lastName
                ? user?.firstName
                  ? user?.firstName.concat(" ", user?.lastName)
                  : user?.lastName
                : user?.firstName
                  ? user?.firstName
                  : undefined,
            });
            Sentry.setUser({
              id: user?.id,
              email: user?.email,
            });
            Sentry.setContext("profile", {
              ...user,
            });
            // if user's afUserId is !== to their user.cuid update it here
            if (user.cuid !== afUserId) {
              // mutation hook is causing compilation failures so im using the client
              apolloClient
                .mutate({
                  mutation: UPDATE_USER_CUID,

                  variables: {
                    userId: user.id,
                    afWebUserId: afUserId,
                  },
                  context: { clientName: "rapptr" },
                })
                .then((response: any) => console.log(response))
                .catch((err: any) => {
                  console.log(err);
                });
            }
          } else {
            console.log("invalid auth token, redirecting to login");
            logout();
            router.push({ pathname: "/login" });
          }
        });
    }
  }, [auth0UserId]);

  const generateWebPageTitle = (pathName: string) => {
    const pathNoSearch = pathName.split("?")[0];
    const parts = pathNoSearch.split("/");
    const editedParts = parts.map((part, index) => {
      if (index !== parts.length - 1) {
        return capitalize(part) + " - ";
      }
      return capitalize(part);
    });

    if (pathName !== "/") {
      editedParts[0] = "Alter | ";
      return editedParts.join("");
    }
    return "Alter";
  };

  return (
    <>
      <style jsx global>
        {`
          :root {
            --modern-gothic: ${fontModernGothic?.style?.fontFamily};
            --inter: ${inter?.style.fontFamily};
            --modern-gothic-trial: ${fontModernGothicTrial?.style.fontFamily};
          }
        `}
      </style>
      <ApolloProvider client={apolloClient}>
        {/* <UserContextProvider> */}
        <ShopContextProvider>
          <UIContextProvider>
            <>
              <Head>
                <title>{generateWebPageTitle(router.asPath)}</title>
                <meta name="theme-color" content="#FFFCFF" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0"
                />
                <meta
                  property="og:title"
                  content="Personalized fitness system using DNA and biometric data"
                />
                <meta
                  property="og:image"
                  content="/Alter_Product_Mirror_Workout.webp"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png?v=2"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png?v=2"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png?v=2"
                />
                <link rel="manifest" href="/site.webmanifest?v=2" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg?v=2"
                  color="#000000"
                />
                <link rel="shortcut icon" href="/favicon.ico?v=2" />
                <meta name="msapplication-TileColor" content="#000000" />
                {/* <script
                  type="text/javascript"
                  src="/js/createAuthToken.js"
                  defer
                /> */}
              </Head>
              {getLayout(<Component {...pageProps} />)}
            </>
          </UIContextProvider>
        </ShopContextProvider>
        {/* </UserContextProvider> */}
      </ApolloProvider>
      <ToastContainer
        position="top-right"
        // autoClose={2500}
        hideProgressBar
        className="w-full mt-12 md:mt-38 md:mr-74 p-0"
        icon={(props) => <></>}
        toastClassName={(props) =>
          clsx(
            "bg-alter-black-80 text-alter-bone break-keep w-96",
            props?.type === "error" && "bg-alter-red",
            props?.type === "warning" && "bg-alter-warning text-alter-black",
            "rounded-lg text-base flex flex-row items-center justify-start gap-5 pr-4"
          )
        }
        closeButton={(props) => (
          <Image width={16} height={16} src={Close} alt="logo" />
        )}
        bodyClassName="p-0"
      />
    </>
  );
}
export default App;
